:root {
	--phone-input-bg: #333;
}

body[data-dark-mode] {
	--phone-input-bg: #fff;
}

.phone-input-container .PhoneInputCountry::after {
	content: var(--country);
	font: inherit;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.5rem;
	margin-left: 0.5rem;
}

.phone-input-container .PhoneInputCountry {
	color: var(--phone-input-bg);
	height: 3.5rem;
	margin-right: 1rem;
	padding-right: 1rem;
	padding-left: 1rem;
	border: 1px solid rgba(145 158 171 / 0.32);
	border-radius: 0.5rem;
}
