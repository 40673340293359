body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    
}
*, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
   }

body * {
	scrollbar-width: none !important;
}
::-webkit-scrollbar {
	width: 0; /* Remove scrollbar space */
	background: transparent; /* Optional: just make scrollbar invisible */
}
body,
body * {
	font-family: 'Public Sans';
}

#root {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: white;

}
